import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/bstft-application`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getByWhere: () => `${RESOURCE_ENDPOINT}/getByWhere`,
    getByApplicationId: (applicationId: any) => `${RESOURCE_ENDPOINT}/application/${applicationId}`,
    create: () => `${RESOURCE_ENDPOINT}`,
    createOpening: () => `${RESOURCE_ENDPOINT}/opening-data`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updateOpening: (id: Number) => `${RESOURCE_ENDPOINT}/opening-data/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    getBudgetSummary: () => `${RESOURCE_ENDPOINT}/getBudgetSummary`,
    updateMeritAndWaitingSerial: () => `${RESOURCE_ENDPOINT}/updateMeritAndWaitingSerial`,
    approvePreviousData: (id: Number) => `${RESOURCE_ENDPOINT}/approve-previous-data/${id}`,
};

export default class BSTFTApplicationApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();

        return HttpService.get(url, params, headers);
    };

   /*  public approvePreviousData = (payload = {}, params = {}): AxiosPromise<any> => {
        const url = endpoints.approvePreviousData();
        return HttpService.post(url, payload, params);
    }; */
    public approvePreviousData = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.approvePreviousData(id);
        return HttpService.post(url, payload, params, headers);
    };

    public updateMeritAndWaitingSerial = (payload = {}, params = {}): AxiosPromise<any> => {
        const url = endpoints.updateMeritAndWaitingSerial();
        return HttpService.post(url, payload, params);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public getByWhere = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getByWhere();
        return HttpService.get(url, params, headers);
    }

    public getByApplicationId = (applicationId: any): AxiosPromise<any> => {
        const url = endpoints.getByApplicationId(applicationId);
        return HttpService.get(url);
    };

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    };

    public createOpening = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.createOpening();
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updateOpening = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updateOpening(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };
    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };

    public getBudgetSummary = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBudgetSummary();
        return HttpService.get(url, params, headers);
    }
}
