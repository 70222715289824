import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/import`
const endpoints = {
    importExample: () => `${RESOURCE_ENDPOINT}/example`,
    importSynopsisOfResearchProject: () => `${RESOURCE_ENDPOINT}/synopsis-of-research-project`,
    importPreviousApprovedProject: () => `${RESOURCE_ENDPOINT}/previous-approved-project`,
    importRnDPreviousProject: () => `${RESOURCE_ENDPOINT}/rnd-previous-approved-project`,
    importNSTMarkDistribution: () => `${RESOURCE_ENDPOINT}/nst-mark-distribution`,
    importScienceClubRecommendedAmount: () => `${RESOURCE_ENDPOINT}/science-club-recommended-amount`,
    importNonGovClubRecommendedAmount: () => `${RESOURCE_ENDPOINT}/non-gov-recommended-amount`,
    importRandProjectMostRecommendedAmount: () => `${RESOURCE_ENDPOINT}/rand-project-most-recommended-amount`,
    importSRGMarkDistribution: () => `${RESOURCE_ENDPOINT}/srg-mark-distribution`,
}

export default class ImportGrantApi {
    public importExample = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importExample();
        return HttpService.post(url, payload, params, headers);
    }
    public importSynopsisOfResearchProject = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importSynopsisOfResearchProject();
        return HttpService.post(url, payload, params, headers);
    }
    public importPreviousApprovedProject = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importPreviousApprovedProject();
        return HttpService.post(url, payload, params, headers);
    }
    public importRnDPreviousProject = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importRnDPreviousProject();
        return HttpService.post(url, payload, params, headers);
    }
    public importNSTMarkDistribution = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importNSTMarkDistribution();
        return HttpService.post(url, payload, params, headers);
    }
    public importScienceClubRecommendedAmount = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importScienceClubRecommendedAmount();
        return HttpService.post(url, payload, params, headers);
    }
    public importNonGovClubRecommendedAmount = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importNonGovClubRecommendedAmount();
        return HttpService.post(url, payload, params, headers);
    }
    public importRandProjectMostRecommendedAmount = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importRandProjectMostRecommendedAmount();
        return HttpService.post(url, payload, params, headers);
    }
    public importSRGMarkDistribution = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.importSRGMarkDistribution();
        return HttpService.post(url, payload, params, headers);
    }
}

