import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/grant-admin-dashboard`
const endpoints = {
    // Count
    countService: () => `${RESOURCE_ENDPOINT}/count/service`,
    countSubmitApplication: () => `${RESOURCE_ENDPOINT}/count/submit-application`,
    countServiceRecipient: () => `${RESOURCE_ENDPOINT}/count/service-recipient`,
    countResearcherProfile: () => `${RESOURCE_ENDPOINT}/count/researcher-profile`,
    countOrganization: () => `${RESOURCE_ENDPOINT}/count/organization`,
    countPaymentCollectionAmount: () => `${RESOURCE_ENDPOINT}/count/payment-collection-amount`,
    countBillSubmitted: () => `${RESOURCE_ENDPOINT}/count/bill-submitted`,
    countDisbursementAmount: () => `${RESOURCE_ENDPOINT}/count/disbursement-amount`,
    // Summary
    summaryNstFellowship: () => `${RESOURCE_ENDPOINT}/summary/nst-fellowship`,
    summarySpecialResearchGrant: () => `${RESOURCE_ENDPOINT}/summary/special-research-grant`,
    summaryRndProject: () => `${RESOURCE_ENDPOINT}/summary/rnd-project`,
    summaryBstft: () => `${RESOURCE_ENDPOINT}/summary/bstft`,
    summaryNonGovtInstitute: () => `${RESOURCE_ENDPOINT}/summary/non-govt-institute`,
    summaryScientificOrganization: () => `${RESOURCE_ENDPOINT}/summary/scientific-organization`,
    summaryInternshipThesisApplication: () => `${RESOURCE_ENDPOINT}/summary/internship-thesis-application`,
    summaryResearchFellowship: () => `${RESOURCE_ENDPOINT}/summary/research-fellowship`,
    summaryLagshoiProzukti: () => `${RESOURCE_ENDPOINT}/summary/lagshoi-prozukti`,
    summaryResearcherProfile: () => `${RESOURCE_ENDPOINT}/summary/researcher-profile`,
}

export default class GrandAdminDashboardApi {
    public countService = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countService();
        return HttpService.post(url, payload, params, headers);
    }

    public countSubmitApplication = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countSubmitApplication();
        return HttpService.post(url, payload, params, headers);
    }

    public countServiceRecipient = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countServiceRecipient();
        return HttpService.post(url, payload, params, headers);
    }

    public countResearcherProfile = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countResearcherProfile();
        return HttpService.post(url, payload, params, headers);
    }

    public countOrganization = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countOrganization();
        return HttpService.post(url, payload, params, headers);
    }

    public countPaymentCollectionAmount = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countPaymentCollectionAmount();
        return HttpService.post(url, payload, params, headers);
    }

    public countBillSubmitted = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countBillSubmitted();
        return HttpService.post(url, payload, params, headers);
    }

    public countDisbursementAmount = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.countDisbursementAmount();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryNstFellowship = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryNstFellowship();
        return HttpService.post(url, payload, params, headers);
    }

    public summarySpecialResearchGrant = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summarySpecialResearchGrant();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryRndProject = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryRndProject();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryBstft = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryBstft();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryNonGovtInstitute = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryNonGovtInstitute();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryScientificOrganization = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryScientificOrganization();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryInternshipThesisApplication = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryInternshipThesisApplication();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryResearchFellowship = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryResearchFellowship();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryLagshoiProzukti = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryLagshoiProzukti();
        return HttpService.post(url, payload, params, headers);
    }

    public summaryResearcherProfile = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.summaryResearcherProfile();
        return HttpService.post(url, payload, params, headers);
    }
}
