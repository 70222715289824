import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "@/constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.UTIL_SERVER_PREFIX}/messaging`
const endpoints = {
    sendMessage: () => `${RESOURCE_ENDPOINT}/send-message`,
}

export default class MessagingApi {
    public sendMessage = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.sendMessage();
        return HttpService.post(url, payload, params, headers);
    }
}