import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export const useLang = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const lang = i18n.language || 'en';
    const isEnglish = i18n.language === 'en';
    const isBangla = i18n.language === 'bn';

    const changeLanguage = (locale: string) => {
        if (lang !== locale && locale === 'en') {
            localStorage.setItem('lang', locale);
            i18n.changeLanguage(locale);
            handleUrl(locale);
        }
        if (lang !== locale && locale === 'bn') {
            localStorage.setItem('lang', locale);
            i18n.changeLanguage(locale);
            handleUrl(locale);
        }
    }

    const changeLanguageFromUrl = () => {
        if (location.pathname.includes('/en/')) {
            localStorage.setItem('lang', 'en');
            i18n.changeLanguage('en');
            setHtmlLangAttribute('en');
        }
        else if (location.pathname.includes('/bn/')) {
            localStorage.setItem('lang', 'bn');
            i18n.changeLanguage('bn');
            setHtmlLangAttribute('bn');
        }
    }

    const handleUrl = (locale: string): void => {
        if (locale === 'en' && location.pathname.includes('/bn/')) {
            setHtmlLangAttribute(locale);
            const updatedPathname = location.pathname.replace('/bn/', '/en/');
            navigate({
                pathname: updatedPathname,
                search: location.search
            });
        }
        else if (locale === 'bn' && location.pathname.includes('/en/')) {
            setHtmlLangAttribute(locale);
            const updatedPathname = location.pathname.replace('/en/', '/bn/');
            navigate({
                pathname: updatedPathname,
                search: location.search
            });
        }
    }

    const getLangUrl = (language: string, url: string, urlPrefix: null | string = null, externalUrl: boolean = false) => {
        if (externalUrl) {
            return urlPrefix ? `${urlPrefix}/${language + url}` : `${language + url}`;
        }
        else {
            return urlPrefix ? `/${urlPrefix}/${language + url}` : `/${language + url}`;
        }
    }

    const getPrintLangUrl = (language: string, url: string) => {
        if (url.indexOf("admin") > -1) {
            return url.replace(`/admin/${language}/`, `/admin/${language}/print-preview/`);
        }
        else if (url.indexOf("my-account") > -1) {
            return url.replace(`/my-account/${language}/`, `/my-account/${language}/print-preview/`);
        }
        else {
            return url.replace(`/${language}/`, `/${language}/print-preview/`);
        }
    }

    const getUserLangUrl = (language: string, url: string) => {
        return `/my-account/${language + url}`;
    }

    const getAdminLangUrl = (language: string, url: string) => {
        return `/admin/${language + url}`;
    }

    const getLangPath = (language: string, path: string) => {
        return `/${language + path}`;
    }

    const getUserLangPath = (language: string, path: string) => {
        return `/my-account/${language + path}`;
    }

    const getAdminLangPath = (language: string, path: string) => {
        return `/admin/${language + path}`;
    }

    const getCurrentUrl = () => {
        return window.location.href;
    }

    const setHtmlLangAttribute = (langCode: string) => {
        document.documentElement.setAttribute('lang', langCode);
    }

    return { t, lang, isEnglish, isBangla, changeLanguage, changeLanguageFromUrl, getLangPath, getPrintLangUrl, getUserLangPath, getAdminLangPath, getLangUrl, getUserLangUrl, getAdminLangUrl, getCurrentUrl }
}
