import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "@/services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/researcher-profile`

const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    create: () => `${RESOURCE_ENDPOINT}`,
    createConfidentialInfo: () => `${RESOURCE_ENDPOINT}/store-researcher-confidential-info`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updateConfidentialInfo: (id: Number) => `${RESOURCE_ENDPOINT}/update-researcher-confidential-info/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    deleteConfidentialInfo: (id: Number) => `${RESOURCE_ENDPOINT}/delete-researcher-confidential-info/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    export: () => `${RESOURCE_ENDPOINT}/export`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    academicDegreeHeldList: () => `${RESOURCE_ENDPOINT}/academic-degree-held-list`,
    previousExperienceList: () => `${RESOURCE_ENDPOINT}/previous-experience-list`,
    trainingSeminarCourseList: () => `${RESOURCE_ENDPOINT}/training-seminar-course-list`,
    awardScholarshipList: () => `${RESOURCE_ENDPOINT}/award-scholarship-list`,
    publicationList: () => `${RESOURCE_ENDPOINT}/publication-list`,
    getByUserId: (userId: any) => `${RESOURCE_ENDPOINT}/getByUserId/${userId}`,
    getByResearcherId: (researcherId: any) => `${RESOURCE_ENDPOINT}/getByResearcherId/${researcherId}`,
    getResearcherConfidentialInfoByResearcherId: (researcherId: any) => `${RESOURCE_ENDPOINT}/getResearcherConfidentialInfoByResearcherId/${researcherId}`,
    getPreviousProjectComparisonByResearcherId: (researcherId: any) => `${RESOURCE_ENDPOINT}/getPreviousProjectComparisonByResearcherId/${researcherId}`,
    getSummaryDashboard: () => `${RESOURCE_ENDPOINT}/summary-dashboard`,
}

export default class ResearcherProfileApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    }

    public academicDegreeHeldList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.academicDegreeHeldList();
        return HttpService.get(url, params, headers);
    }

    public previousExperienceList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.previousExperienceList();
        return HttpService.get(url, params, headers);
    }

    public trainingSeminarCourseList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.trainingSeminarCourseList();
        return HttpService.get(url, params, headers);
    }

    public awardScholarshipList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.awardScholarshipList();
        return HttpService.get(url, params, headers);
    }

    public publicationList = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.publicationList();
        return HttpService.get(url, params, headers);
    }

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    }

    public getByUserId = (userId: any): AxiosPromise<any> => {
        const url = endpoints.getByUserId(userId);
        return HttpService.get(url);
    };
    public getByResearcherId = (researcherId: any): AxiosPromise<any> => {
        const url = endpoints.getByResearcherId(researcherId);
        return HttpService.get(url);
    };
    public getResearcherConfidentialInfoByResearcherId = (researcherId: any): AxiosPromise<any> => {
        const url = endpoints.getResearcherConfidentialInfoByResearcherId(researcherId);
        return HttpService.get(url);
    };
    public getPreviousProjectComparisonByResearcherId = (researcherId: any): AxiosPromise<any> => {
        const url = endpoints.getPreviousProjectComparisonByResearcherId(researcherId);
        return HttpService.get(url);
    };

    public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    }
    public createConfidentialInfo = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.createConfidentialInfo();
        return HttpService.post(url, payload, params, headers);
    }

    public update = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    }
    public updateConfidentialInfo = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.updateConfidentialInfo(id);
        return HttpService.put(url, payload, params, headers);
    }

    public updatePartial = (id: any, payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    }

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    }
    public deleteConfidentialInfo = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.deleteConfidentialInfo(id);
        return HttpService.delete(url, params, headers);
    }

    public bulk = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    }

    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    }

    public getSummaryDashboard = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSummaryDashboard();
        return HttpService.post(url, payload, params, headers);
    }

    public export = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.export();
        return HttpService.post(url, payload, params, headers);
    }
}
